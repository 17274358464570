import { gsap } from "gsap";

export const animatedSvg = {
	props: {
		foreground: {
			type: String,
			default: "#FF5729"
		},
		background: {
			type: String,
			default: "transparent"
		},
		playAnimation: {
			type: Boolean,
			default: true
		},
		shouldStop: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			timeline: gsap.timeline({repeat: -1, onRepeat: this.checkShouldStop, paused: true })
		};
	},
	watch: {
		shouldStop(newValue, oldValue) {
			// If the shouldStop prop changes back to false, we want to restart the animation
			if(oldValue && this.playAnimation && this.timeline) {
				this.timeline.play();
			}
		}
	},
	methods: {
		onEnter() {
			if(this.playAnimation) {
				this.entryAnimation();
			}
		},
		onLeave() {
			this.killTimeline();
			this.exitAnimation();
		},
		maybePlayTimeline() {
			if(this.playAnimation && !this.shouldStop) {
				this.timeline.play();
			}
		},
		entryAnimation() {
		},
		exitAnimation() {
		},
		checkShouldStop() {
			if (this.shouldStop && this.playAnimation) {
				this.timeline.pause();
			}
		},
		killTimeline() {
			this.timeline.kill()
		}
	}
};
