<template>
	<div class="playground-frame frame-3">
		
		<div>
			<AnimatedSVG :play-animation="true" :active-shape="!beenSeen ? `TwoMountains` : null" :active-colour="foregrounds.persimmon" :should-stop="true" />
			
			<div class="message"><p>Expand<br/>creativity</p></div>
			
			<AnimatedSVG :play-animation="true" :active-shape="!beenSeen ? 'Decagon' : null" :active-colour="foregrounds.turf" :should-stop="true" />
			
			<AnimatedSVG :play-animation="true" :active-shape="!beenSeen ? 'TwoHalfCircles' : null" :active-colour="foregrounds.cobalt" :should-stop="true" />
		</div>
		
		<div class="big-shape">
			<AnimatedSVG :play-animation="true" active-shape="CurvedDiamond" :active-colour="foregrounds.persimmon" :active-background-colour="foregrounds.cobalt" :should-stop="false" />
		</div>

		
	</div>
</template>

<script>
import AnimatedSVG from "@/components/AnimatedSVG";
import { branding } from "@/mixins/brand";

export default {
	name: "Frame3",
	components: { AnimatedSVG },
	props: {
		beenSeen: {
			type: Boolean,
			default: false
		}
	},
	mixins: [branding],
};
</script>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";

.frame-3 {
	grid-template-columns: repeat(2, 1fr);
	@media screen and (max-width: $sm) {
		grid-template-columns: repeat(1, 1fr);
	}
	> div {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
	.big-shape {
		.animated-shape {
			grid-column: 1/3;
		}
	}
}
</style>