<template>
	<div class="scroll-container">
		
		<div class="scroller">
			
			<div class="group">
				<div>
					<EightHorizontalArrowsSVG :play-animation="false" :foreground="foregrounds.persimmon" />
				</div>
				<div>
					<CircleSVG :play-animation="false" :foreground="foregrounds.turf" />
				</div>
				<div>
					<ThreeHorizonLinesSVG :play-animation="false" :foreground="foregrounds.ultramarine" />
				</div>
				<div>
					<DiamondSVG :play-animation="false" :foreground="foregrounds.persimmon" />
				</div>
				<div>
					<TwoMountainsSVG :play-animation="false" :foreground="foregrounds.ultramarine" />
				</div>
				<div>
					<TwoHalfCirclesSVG :play-animation="false" :foreground="foregrounds.cobalt" />
				</div>
			</div>
			
			<div class="group text"><p>Respect</p></div>
			
			<div class="group">
				<div>
					<CurvedDiamondSVG :play-animation="false" :foreground="foregrounds.turf" />
				</div>
				<div>
					<DiagonalArrowsSVG :play-animation="false" :foreground="foregrounds.sky" />
				</div>
				<div>
					<StarSVG :play-animation="false" :foreground="foregrounds.turf" />
				</div>
				<div>
					<TwoSmilesSVG :play-animation="false" :foreground="foregrounds.persimmon" />
				</div>
				<div>
					<FourCirclesSVG :play-animation="false" :foreground="foregrounds.cobalt" />
				</div>
				<div>
					<CheckerboardSVG :play-animation="false" :foreground="foregrounds.turf" />
				</div>
			</div>
			
			<div class="group text"><p>Manners</p></div>
			
			<div class="group">
				<div>
					<TwoSmilesSVG :play-animation="false" :foreground="foregrounds.cobalt" />
				</div>
				<div>
					<CircleSVG :play-animation="false" :foreground="foregrounds.turf" />
				</div>
				<div>
					<ThreeHorizonLinesSVG :play-animation="false" :foreground="foregrounds.persimmon" />
				</div>
				<div>
					<StarSVG :play-animation="false" :foreground="foregrounds.turf" />
				</div>
				<div>
					<TwoMountainsSVG :play-animation="false" :foreground="foregrounds.ultramarine" />
				</div>
				<div>
					<EightHorizontalArrowsSVG :play-animation="false" :foreground="foregrounds.persimmon" />
				</div>
			</div>
			
			<div class="group text"><p>Try Your Best</p></div>
		
		</div>
	
	</div>
</template>

<script>
import { branding } from "@/mixins/brand";

import EightHorizontalArrowsSVG from "@/components/svg-blocks/EightHorizontalArrowsSVG";
import CircleSVG from "@/components/svg-blocks/CircleSVG";
import ThreeHorizonLinesSVG from "@/components/svg-blocks/ThreeHorizontalLinesSVG";
import DiamondSVG from "@/components/svg-blocks/DiamondSVG";
import TwoMountainsSVG from "@/components/svg-blocks/TwoMountainsSVG";
import TwoHalfCirclesSVG from "@/components/svg-blocks/TwoHalfCirclesSVG";
import CurvedDiamondSVG from "@/components/svg-blocks/CurvedDiamondSVG";
import DiagonalArrowsSVG from "@/components/svg-blocks/DiagonalArrowsSVG";
import StarSVG from "@/components/svg-blocks/StarSVG";
import TwoSmilesSVG from "@/components/svg-blocks/TwoSmilesSVG";
import FourCirclesSVG from "@/components/svg-blocks/FourCirclesSVG";
import CheckerboardSVG from "@/components/svg-blocks/CheckerboardSVG";

import { gsap, Linear } from "gsap";

export default {
	name: "Scroller",
	components: {
		CheckerboardSVG,
		FourCirclesSVG,
		TwoSmilesSVG,
		StarSVG,
		DiagonalArrowsSVG,
		CurvedDiamondSVG,
		TwoHalfCirclesSVG,
		TwoMountainsSVG,
		DiamondSVG,
		ThreeHorizonLinesSVG,
		CircleSVG,
		EightHorizontalArrowsSVG
	},
	mixins: [branding],
	mounted() {
		
		gsap.set(".group", {
			x: function(i) {
				return i * 390;
			}
		});
		
		let totalWidth = 390 * 6;
		
		gsap.timeline().to(".group", {
			x: "-=" + totalWidth,
			modifiers: {
				x: x => this.mod(parseFloat(x), totalWidth) + "px"
			},
			duration: 20,
			repeat: -1,
			ease: Linear.easeNone
		});
		
	},
	methods: {
		mod(num, div) {
			return ((num % div) + div) % div;
		}
	}
};
</script>

<style lang="scss">

@import "~@/assets/scss/_variables.scss";

.scroll-container {
	overflow: hidden;
	background: $caterpillar;
	position: relative;
	width: auto;
	
	.scroller {
		height: 65px;
		overflow: hidden;
		position: relative;
		left: -390px;
		width: calc(100% + 390px);
		
		.group {
			top: 0;
			width: 390px;
			height: 65px;
			display: block;
			text-align: center;
			position: absolute;
			
			> div {
				width: 65px;
				display: inline-block;
			}
			
		}
		
		.text {
			p {
				margin: 0;
				padding: 0;
			}
		}
		
		p {
			color: $cobalt;
			font-size: 50px;
			font-weight: 500;
			line-height: 65px;
		}
		
	}
}
</style>
