<template>
	<Transition appear @enter="onEnter" @before-leave="onLeave" mode="out-in">
		<div class="svg-shape svg-diagonal-arrows" :style="{ background }">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
				<g ref="diagonalArrows">
					<path
						d="M0,166.7,333.3,500V166.7Z"
						transform="translate(0)"
						:fill="foreground" />
					<path
						d="M166.7,0,500,333.3V0Z"
						transform="translate(0)"
						:fill="foreground" />
					<path
						d="M0,166.7,333.3,500V166.7Z"
						transform="translate(-500, 500)"
						:fill="foreground" />
					<path
						d="M166.7,0,500,333.3V0Z"
						transform="translate(-500, 500)"
						:fill="foreground" />
				</g>
			</svg>
		</div>
	</Transition>
</template>

<script>
	import { animatedSvg } from "@/mixins/animated-svg";
	import { gsap, Linear } from "gsap";

	export default {
		name: "DiagonalArrowsSVG",
		mixins: [animatedSvg],
		mounted() {
			if (this.playAnimation) {
				const { diagonalArrows } = this.$refs;
				gsap.set(diagonalArrows, { x: "0", y: "0" });
				gsap.set(diagonalArrows.children, {scale: 0, transformOrigin: "100% 0%"});
				this.timeline.to(diagonalArrows, { duration: 1.5, x: "50%", y: "-50%", ease: Linear.easeNone }, "0");
			}
		},
		methods: {
			entryAnimation() {
				const { diagonalArrows } = this.$refs;
				gsap.to(diagonalArrows.children, {scale: 1, onComplete:this.maybePlayTimeline});
			}
		}
	};
</script>
