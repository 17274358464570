<template>
	<Transition appear @enter="onEnter" @before-leave="onLeave" mode="out-in">
		<div class="svg-shape svg-decagon" :style="{ background }">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
				<g ref="decagon">
					<polygon points="327.25 0 172.75 0 47.75 95.55 0 250.15 47.75 404.76 172.75 500.2 327.25 500.2 452.25 404.76 500.2 250.15 452.25 95.55 327.25 0" :fill="foreground"/>
					<polygon transform="translate(0, 499)" points="327.25 0 172.75 0 47.75 95.55 0 250.15 47.75 404.76 172.75 500 327.25 502 452.25 404.76 502 250.15 452.25 95.55 327.25 0" :fill="foreground"/>
				</g>
			</svg>
		</div>
	</Transition>
</template>

<script>
	import { animatedSvg } from "@/mixins/animated-svg";
	import { gsap } from "gsap";

	export default {
		name: "DecagonSVG",
		mixins: [animatedSvg],
		mounted() {
			if (this.playAnimation) {
				const { decagon } = this.$refs;
				gsap.set(decagon.children, { transformOrigin: "50% 50%", scale: 0 });
				this.timeline.to(decagon,{ duration: 1.2, y: "-50%" });
			}
		},
		methods: {
			entryAnimation() {
				const {decagon} = this.$refs;
				gsap.to(decagon.children, {scale: 1, duration: 0.5, onComplete: this.maybePlayTimeline});
			}
		}
	};
</script>
