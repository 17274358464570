<template>
	<div class="animated-shape">
		<component
			:is="`${shape}SVG`"
			:foreground="foregroundColour"
			:background="backgroundColour"
			:play-animation="playAnimation"
			:should-stop="shouldStop"
		/>
	</div>
</template>

<script>
	import { branding } from "@/mixins/brand";

	import CircleSVG from "@/components/svg-blocks/CircleSVG";
	import StarSVG from "@/components/svg-blocks/StarSVG";
	import CurvedDiamondSVG from "@/components/svg-blocks/CurvedDiamondSVG";
	import DiagonalArrowsSVG from "@/components/svg-blocks/DiagonalArrowsSVG";
	import FourCirclesSVG from "@/components/svg-blocks/FourCirclesSVG";
	import ThreeHorizontalLinesSVG from "@/components/svg-blocks/ThreeHorizontalLinesSVG";
	import TwoVerticalArrowsSVG from "@/components/svg-blocks/TwoVerticalArrowsSVG";
	import TwoHalfCirclesSVG from "@/components/svg-blocks/TwoHalfCirclesSVG";
	import OneSmileSVG from "@/components/svg-blocks/OneSmileSVG";
	import TwoHorizontalLinesSVG from "@/components/svg-blocks/TwoHorizontalLinesSVG";
	import CheckerboardSVG from "@/components/svg-blocks/CheckerboardSVG";
	import EightHorizontalArrowsSVG from "@/components/svg-blocks/EightHorizontalArrowsSVG";
	import DecagonSVG from "@/components/svg-blocks/DecagonSVG";
	import DiamondSVG from "@/components/svg-blocks/DiamondSVG";
	import TwoSmilesSVG from "@/components/svg-blocks/TwoSmilesSVG";
	import TwoMountainsSVG from "@/components/svg-blocks/TwoMountainsSVG";

	export default {
		name: "AnimatedSVG",
		data() {
			return {
				shapes: [
					"Circle",
					"Star",
					"CurvedDiamond",
					"DiagonalArrows",
					"FourCircles",
					"TwoVerticalArrows",
					"TwoHalfCircles",
					"OneSmile",
					"ThreeHorizontalLines",
					"TwoHorizontalLines",
					"Checkerboard",
					"EightHorizontalArrows",
					"Decagon",
					"Diamond",
					"TwoSmiles",
					"TwoMountains"
				],
				updatedShape: null,
				updatedColour: null,
				updatedBackgroundColour: null
				
			};
		},
		mixins: [branding],
		components: {
			TwoMountainsSVG,
			TwoSmilesSVG,
			DiamondSVG,
			DecagonSVG,
			EightHorizontalArrowsSVG,
			CheckerboardSVG,
			TwoHorizontalLinesSVG,
			OneSmileSVG,
			TwoHalfCirclesSVG,
			TwoVerticalArrowsSVG,
			ThreeHorizontalLinesSVG,
			FourCirclesSVG,
			DiagonalArrowsSVG,
			CurvedDiamondSVG,
			StarSVG,
			CircleSVG
		},
		props: {
			activeShape: {
				type: String,
				default: null
			},
			activeColour: {
				type: String,
				default: null
			},
			activeBackgroundColour: {
				type:String,
				default: null
			},
			playShapeTransition: {
				type: Boolean,
				default: true
			},
			playAnimation: {
				type: Boolean,
				default: false
			},
			shouldStop: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			foregroundColour() {
				// If colour is changed from its initial setting use it. Otherwise:
				// If there is a colour passed in as a prop, use that. Otherwise:
				// Use a random one from the list of options in the brand mixin
				return (
					this.updatedColour ??
					this.activeColour ??
					this.getRandomForegroundColour()
				);
			},
			backgroundColour() {
				// If colour is changed from its initial setting use it. Otherwise:
				// If there is a colour passed in as a prop, use that. Otherwise:
				// Use a random one from the list of options in the brand mixin
				return (
					this.updatedBackgroundColour ??
					this.activeBackgroundColour ??
					'transparent'
				);
			},
			shape() {
				// If shape is changed from its initial setting use it. Otherwise:
				// If there is a shape passed in as a prop, use that. Otherwise:
				// Use a random one from the list of options
				return (
					this.updatedShape ??
					this.activeShape ??
					this.shapes[Math.floor(Math.random() * this.shapes.length)]
				);
			}
		}
	};
</script>

<style lang="scss">
	.animated-shape {
		display: inline-block;

	}
</style>
