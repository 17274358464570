<template>
	<Transition appear @enter="onEnter" @before-leave="onLeave" mode="out-in">
		<div class="svg-shape svg-two-mountains" :style="{ background }">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
				<g ref="mountains">
					<path
						ref="topLeft"
						d="M124.1,0H248.2c0,137.1-111.1,248.3-248.3,248.3V124.1A124.22,124.22,0,0,0,87.7,87.7,123.4,123.4,0,0,0,124.1,0Z"
						:fill="foreground" />
					<path
						ref="topRight"
						d="M373.6,0H250c0,137.1,112.3,248.3,250.7,250V124.1a125.91,125.91,0,0,1-88.6-36.4A123,123,0,0,1,373.6,0Z"
						:fill="foreground" />
					<path
						ref="bottomLeft"
						d="M124.1,248.3H248.2C248.3,386.7,137.1,500,0,500V373.6a123.67,123.67,0,0,0,87.8-36.7A125.81,125.81,0,0,0,124.1,248.3Z"
						:fill="foreground" />
					<path
						ref="bottomRight"
						d="M373.6,248.3H248.3C248.3,386.7,360.5,500,500,500V373.6A125.47,125.47,0,0,1,373.6,248.3Z"
						:fill="foreground" />
				</g>
			</svg>
		</div>
	</Transition>
</template>

<script>
	import { animatedSvg } from "@/mixins/animated-svg";
	import { gsap } from "gsap";

	export default {
		name: "TwoMountainsSVG",
		mixins: [animatedSvg],
		mounted() {
			if (this.playAnimation) {
				const { topLeft, topRight, bottomLeft, bottomRight } = this.$refs;

				gsap.set(topLeft, { transformOrigin: "left top" });
				gsap.set(bottomLeft, { transformOrigin: "left top" });
				gsap.set(topRight, { transformOrigin: "right top" });
				gsap.set(bottomRight, { transformOrigin: "right top" });

				this.timeline.repeatDelay(1);
				
				this.timeline.to(topRight, { duration: 0.5, rotation: -45 }, 0);
				this.timeline.to(topRight, { duration: 0.5, rotation: 0 }, 0.5);
				
				this.timeline.to(topLeft, { duration: 0.5, rotation: 45 }, 0);
				this.timeline.to(topLeft, { duration: 0.5, rotation: 0 }, 0.5);
				
				this.timeline.to(bottomRight, { duration: 0.5, rotation: -45 }, 0);
				this.timeline.to(bottomRight, { duration: 0.5, rotation: 0 }, 0.5);
				
				this.timeline.to(bottomLeft, { duration: 0.5, rotation: 45 }, 0);
				this.timeline.to(bottomLeft, { duration: 0.5, rotation: 0 }, 0.5);
			}
		},
		methods: {
			entryAnimation() {
				const {mountains} = this.$refs;
				gsap.set(mountains, {scale: 0, transformOrigin: "50% 50%"});
				gsap.to(mountains, {scale: 1, duration: 0.5, onComplete: this.maybePlayTimeline});
			}
		}
	};
</script>
