<template>
  <section class="lottie-animation-section" ref="lottie" >
			<lottie-animation
				ref="player"
				:animationData="require('@/assets/lottie/lf20_sm5zcdqd.json')"
				:loop="false"
				:autoPlay="false"
				:speed="1"
			/>
  </section>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
import { branding } from "@/mixins/brand";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "KCSAnimatedLogo",
	components: {LottieAnimation},
  mixins: [branding],
	mounted() {
		const {lottie, player} = this.$refs;

		ScrollTrigger.create({
			trigger: lottie,
			// markers: true,
			start: "top center",
			end: "bottom center",
			onEnter: () => {
				player.anim.goToAndPlay(1, true);
			},
			onEnterBack: () => {
				player.anim.goToAndPlay(1, true);
			}
		});

		setTimeout(() => {
			ScrollTrigger.refresh();
		}, 100);

	}
};
</script>

<style lang="scss">
@import "~@/assets/scss/variables";

.lottie-animation-section {
  min-height: auto;
	height: auto;
	//height: 100vh;
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	div {
		width: 100%;
	}
	svg {
		width: 100%;
	}
  @media screen and (max-width: $sm) {
    padding: 20px 0;
    div {
      transform: scale(1.5);
    }
  }
}
</style>
