<template>
	<Transition appear @enter="onEnter" @before-leave="onLeave" mode="out-in">
		<div class="svg-shape svg-curved-diamond" :style="{ background }">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
				<g ref="curvedDiamond" transform="translate(-250, -250)">
					<circle cx="250" cy="250" r="250" :fill="foreground"/>
					<circle cx="750" cy="750" r="250" :fill="foreground"/>
					<circle cx="750" cy="250" r="250" :fill="foreground"/>
					<circle cx="250" cy="750" r="250" :fill="foreground"/>
					<rect y="248.5" width="250" height="500" :fill="foreground"/>
					<rect x="126.5" y="-375" width="250" height="500" transform="translate(626 377) rotate(-90)" :fill="foreground"/>
					<rect x="126.5" y="375" width="250" height="500" transform="translate(-124 1127) rotate(-90)" :fill="foreground"/>
					<rect x="750" y="248.5" width="250" height="500" :fill="foreground"/>
				</g>
			</svg>
		</div>
	</Transition>
</template>

<script>
	import { animatedSvg } from "@/mixins/animated-svg";
	import { gsap, Linear } from "gsap";

	export default {
		name: "CurvedDiamondSVG",
		mixins: [animatedSvg],
		mounted() {
			if (this.playAnimation) {
				const { curvedDiamond } = this.$refs;

				gsap.set(curvedDiamond, { transformOrigin: "50% 50%" });
				gsap.set(curvedDiamond.children, { scale: 0, transformOrigin: "50% 50%" });
				
				this.timeline.repeatDelay(1);
				this.timeline.to(curvedDiamond, { duration: 1, rotation: 90, ease: Linear.easeNone }, 0);
			}
		},
		methods: {
			entryAnimation() {
				const { curvedDiamond } = this.$refs;
				gsap.to(curvedDiamond.children, {scale: 1, duration: 0.5, onComplete: this.maybePlayTimeline});
			}
		}
	};
</script>
