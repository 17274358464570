<template>
	<Transition appear @enter="onEnter" @before-leave="onLeave" mode="out-in">
		<div class="svg-shape svg-three-horizontal-lines" :style="{ background }">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
				<g ref="lines">
					<path ref="topLine" d="M0,125.6H500V0H0Z" :fill="foreground" />
					<path ref="middleLine" d="M0,312.8H500V187.2H0Z" :fill="foreground" />
					<path ref="bottomLine" d="M0,500H500V374.4H0Z" :fill="foreground" />
				</g>
			</svg>
		</div>
	</Transition>
</template>

<script>
	import { animatedSvg } from "@/mixins/animated-svg";
	import { gsap } from "gsap";

	export default {
		name: "ThreeHorizonLinesSVG",
		mixins: [animatedSvg],
		mounted() {
			if (this.playAnimation) {
				const { topLine, middleLine, bottomLine } = this.$refs;

				gsap.set(topLine, { x: 0 });
				gsap.set(middleLine, { x: 0 });
				gsap.set(bottomLine, { x: 0 });

				this.timeline.repeatDelay(1);
				
				this.timeline.to(topLine, { duration: 1, x: "33%" }, 0);
				this.timeline.to(topLine, { duration: 1, x: 0 }, 1);
				
				this.timeline.to(middleLine, { duration: 1, x: "-33%" }, 0);
				this.timeline.to(middleLine, { duration: 1, x: 0 }, 1);
				
				this.timeline.to(bottomLine, { duration: 1, x: "33%" }, 0);
				this.timeline.to(bottomLine, { duration: 1, x: 0 }, 1);
			}
		},
		methods: {
			entryAnimation() {
				const {lines} = this.$refs;
				gsap.set(lines.children, {scale: 0, transformOrigin: "50% 50%"});
				gsap.to(lines.children, {scale: 1, duration: 0.5, onComplete: this.maybePlayTimeline});
			}
		}
	};
</script>
