<template>
	<section class="derek-message-section">
    <div class="container">

      <figure>
        <img src="@/assets/images/photo-9- principal with shape dark@2x.png" alt="Derek Logan smiling in suit with green arch background" />
        <figcaption>Derek Logan, KCS Head of School</figcaption>
      </figure>

      <div class="section-text">
        <h2>A school built on possibilities.</h2>
        <p>Every student is unique. We're thrilled to provide a school that's just as unique. Our curriculum is designed to challenge and reward our students every day with boundless opportunities for future-focused learning.</p>
        <p>A KCS student and lifelong learner has the Habits of Mind, Body and Action needed to embrace challenges and lead a successful and meaningful life. We’ve seen these habits help students discover new passions, purpose, and possibilities.</p>
      </div>
    </div>

  </section>
</template>

<script>
  import { branding } from "@/mixins/brand";

	export default {
		name: "DerekMessage",
    mixins: [branding]
	};
</script>

<style lang="scss">
  @import "~@/assets/scss/variables";

  .derek-message-section {
    background-color: $caterpillar;
    display: flex;
    align-items: center;
		padding: 230px 20px;
		
    @media screen and (max-width: $lg) {
      padding: 80px 20px;
    }

    .container {
      display: grid;
      grid-template-columns: 6fr 5fr;
      align-items: center;
      gap: 90px;

      figure {
        margin: 0;
      }

      img {
        width: 100%;
      }

      figcaption {
        margin-top: 10px;
      }

      @media screen and (max-width: $md) {
        grid-template-columns: 1fr;
        gap: 20px;
        width: 95%;
        margin: 0 auto;
      }
    }
  }
</style>
