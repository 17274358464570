<template>
	<div class="playground-frame frame-1">
		<AnimatedSVG v-for="(shape, n) in shapes" :key="n" :play-animation="true" :active-shape="!beenSeen ? shape.shape : null" :active-colour="shape.colour" :should-stop="!randomIndexes.includes(n)" />
	</div>
</template>

<script>
import AnimatedSVG from "@/components/AnimatedSVG";
import { branding } from "@/mixins/brand";

export default {
	name: "Frame1",
	components: { AnimatedSVG },
	props: {
		beenSeen: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			randomIndexes: [],
			interval: null,
		};
	},
	mixins: [branding],
	mounted() {
		this.fillRandomIndexes();
		
		this.interval = setInterval(() => {
			this.fillRandomIndexes()
		}, 3000);
	},
	beforeDestroy() {
		clearInterval(this.interval);
	},
	computed: {
		shapes() {
			return [
				{ shape: "Star", colour: this.foregrounds.ultramarine },
				{ shape: "TwoVerticalArrows", colour: this.foregrounds.sky },
				{ shape: "EightHorizontalArrows", colour: this.foregrounds.cobalt },
				{ shape: "ThreeHorizontalLines", colour: this.foregrounds.turf },
				
				{ shape: "FourCircles", colour: this.foregrounds.turf },
				{ shape: "TwoHalfCircles", colour: this.foregrounds.persimmon },
				{ shape: "Checkerboard", colour: this.foregrounds.caterpillar },
				{ shape: "CurvedDiamond", colour: this.foregrounds.cobalt }
			];
		},

	},
	methods: {
		fillRandomIndexes() {
			
			let arr = []
			
			for(let i = 0; i < 3; i++) {
				arr.push(this.getRandomInt(0, this.shapes.length - 1));
			}
			
			this.randomIndexes = arr;
		},
		getRandomInt(min, max) {
			return Math.floor(Math.random() * (max - min + 1) + min);
		},
	}
};
</script>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";

.frame-1 {
	grid-template-columns: repeat(4, 1fr);
	@media screen and (max-width: $sm) {
		grid-template-columns: repeat(2, 1fr);
	}
}
</style>