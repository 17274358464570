<template>
	<div class="playground-frame frame-5">
		
		<div>
			
			<AnimatedSVG :play-animation="true" active-shape="Star" :active-colour="foregrounds.cobalt" :active-background-colour="foregrounds.sky" :should-stop="false" />
			
			<AnimatedSVG :play-animation="true" :active-shape="!beenSeen ? 'TwoHalfCircles' : null" :active-colour="foregrounds.cobalt" :should-stop="true" />
			
			<div class="message"><p>Expand<br/>community</p></div>
			
			<AnimatedSVG :play-animation="true" :active-shape="!beenSeen ? 'CurvedDiamond' : null" :active-colour="foregrounds.sky" :should-stop="true" />

		</div>

		<div>
			
			<AnimatedSVG :play-animation="true" :active-shape="!beenSeen ? 'FourCircles' : null" :active-colour="foregrounds.ultramarine" :should-stop="true" />
			
			<AnimatedSVG :play-animation="true" :active-shape="!beenSeen ? 'Checkerboard' : null" :active-colour="foregrounds.caterpillar" :should-stop="true" />
			
			<AnimatedSVG :play-animation="true" :active-shape="!beenSeen ? 'TwoSmiles' : null" :active-colour="foregrounds.caterpillar" :should-stop="true" />
			
			<AnimatedSVG :play-animation="true" active-shape="Star" :active-colour="foregrounds.ultramarine" :active-background-colour="foregrounds.sky" :should-stop="false" />
			
		</div>
		
	</div>
</template>

<script>
import AnimatedSVG from "@/components/AnimatedSVG";
import { branding } from "@/mixins/brand";

export default {
	name: "Frame5",
	components: { AnimatedSVG },
	props: {
		beenSeen: {
			type: Boolean,
			default: false
		}
	},
	mixins: [branding]
};
</script>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";

.frame-5 {
	grid-template-columns: repeat(2, 1fr);
	@media screen and (max-width: $sm) {
		grid-template-columns: repeat(1, 1fr);
	}
	> div {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
}
</style>