<template>
	<section class="brand-video-section" ref="section">

		<div class="video-wrapper">
			<div style="position:relative; overflow:hidden; padding-bottom:56.25%"><iframe src="https://cdn.jwplayer.com/players/koiL3E1X-jZrMMOnb.html" width="100%" height="100%" frameborder="0" scrolling="auto" title="Y2mate.is - Senior Kindergarten Habits Of Mind, Body And Action-ibgdn5n4hpi-720p-1647440268064" style="position:absolute;" allowfullscreen></iframe></div>
		</div>

    <div class="video-text">

      <TwoVerticalArrowsSVG :play-animation="false" :foreground="foregrounds.sky" />

      <p>Evolving since 1989, much of what makes KCS great stays the same. Because what our students learn at school helps them thrive in the world.</p>

      <TwoVerticalArrowsSVG v-for="n in 2" :key="n" :play-animation="false" :foreground="foregrounds.sky" />

      <div class="mobile-only">
        <TwoVerticalArrowsSVG v-for="n in 3" :key="n" :play-animation="false" :foreground="foregrounds.sky" />
      </div>

    </div>

  </section>
</template>

<script>
	import TwoVerticalArrowsSVG from "./svg-blocks/TwoVerticalArrowsSVG";
  import { branding } from "@/mixins/brand";

  export default {
		name: "BrandVideo",
    components: { TwoVerticalArrowsSVG },
    mixins: [branding],
	};
</script>

<style lang="scss">
	@import "~@/assets/scss/variables";

  .brand-video-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
		padding: 230px 0 150px 0;
		background: $ultramarine;
    @media screen and (max-width: $lg) {
      padding: 100px 0 20px 0;
    }
  }

	.video-wrapper {
		padding: 0 40px;
		margin: 50px auto;
		width: 100%;
		max-width: 1240px;
		@media screen and (max-width: $sm) {
			margin: 0 auto;
			padding: 0 20px;
		}

    //styles not applying to player
    //.jw-button-color {
    //  background-color: #39C2D7;
    //  color: $library;
    //  border-radius: 500px;
    //  svg {
    //    transform: scale(0.6);
    //  }
    //}
	}

  .video-text {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr 1fr;
    align-items: center;
    margin-top: 50px;
		margin-bottom: 50px;
    p {
      color: $library;
      font-size: 27px;
      font-weight: 600;
      line-height: 34px;
      max-width: 1000px;
      margin: 0 70px;

      @media screen and (max-width: $md) {
        margin: 0 auto;
        width: 90%;
        font-size: 20px;
        line-height: 25px;
      }
    }

    .svg-shape {
      transform: rotate(90deg);
    }

    @media screen and (max-width: $md) {
      grid-template-columns: 1fr;

      .svg-shape {
        display: none;
      }

      .mobile-only {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        .svg-shape {
          display: block;
          margin: 50px 0 20px;
        }
      }
    }
  }
</style>
