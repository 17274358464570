<template>
	<Transition appear @enter="onEnter" @before-leave="onLeave" mode="out-in">
		<div class="svg-shape svg-four-circles" :style="{ background }">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
				<g ref="leftGroup">
					<path
						d="M375,250A125,125,0,1,0,250,125,125,125,0,0,0,375,250Z"
						:fill="foreground" />
					<path
						d="M375,500A125,125,0,1,0,250,375,125,125,0,0,0,375,500Z"
						:fill="foreground" />
					<path
						d="M375,250A125,125,0,1,0,250,125,125,125,0,0,0,375,250Z"
						transform="translate(0, -500)"
						:fill="foreground" />
					<path
						d="M375,500A125,125,0,1,0,250,375,125,125,0,0,0,375,500Z"
						transform="translate(0, -500)"
						:fill="foreground" />
				</g>
				<g ref="rightGroup">
					<path
						d="M125,250A125,125,0,1,0,0,125,125,125,0,0,0,125,250Z"
						:fill="foreground" />
					<path
						d="M125,500A125,125,0,1,0,0,375,125,125,0,0,0,125,500Z"
						:fill="foreground" />
					<path
						d="M125,250A125,125,0,1,0,0,125,125,125,0,0,0,125,250Z"
						transform="translate(0, 500)"
						:fill="foreground" />
					<path
						d="M125,500A125,125,0,1,0,0,375,125,125,0,0,0,125,500Z"
						transform="translate(0, 500)"
						:fill="foreground" />
				</g>
			</svg>
		</div>
	</Transition>
</template>

<script>
	import { animatedSvg } from "@/mixins/animated-svg";
	import {gsap} from "gsap";
	
	export default {
		name: "FourCirclesSVG",
		mixins: [animatedSvg],
		mounted() {
			if (this.playAnimation) {
				const { rightGroup, leftGroup } = this.$refs;
				this.timeline.repeatDelay(0.5);
				this.timeline.to(rightGroup, { duration: 1, y: "-50%" }, 0);
				this.timeline.to(leftGroup, { duration: 1, y: "50%" }, 0);
			}
		},
		methods: {
			entryAnimation() {
				const {rightGroup, leftGroup} = this.$refs;
				gsap.set(rightGroup.children, {scale: 0, transformOrigin: "50% 50%"});
				gsap.set(leftGroup.children, {scale: 0, transformOrigin: "50% 50%"});
				
				gsap.to(rightGroup.children, {scale: 1, duration: 0.5, onComplete: this.maybePlayTimeline});
				gsap.to(leftGroup.children, {scale: 1, duration: 0.5});
			}
		}
	};
</script>
