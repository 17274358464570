<template>
	<Transition appear @enter="onEnter" @before-leave="onLeave" mode="out-in">
		<div class="svg-shape svg-two-horizontal-lines" :style="{ background }">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
				<g ref="lines">
					<path ref="topLine" d="M500,95H0V219.9H500Z" :fill="foreground" />
					<path ref="bottomLine" d="M500,281.1H0V406H500Z" :fill="foreground" />
				</g>
			</svg>
		</div>
	</Transition>
</template>

<script>
	import { animatedSvg } from "@/mixins/animated-svg";
	import { gsap } from "gsap";

	export default {
		name: "TwoHorizontalLinesSVG",
		mixins: [animatedSvg],
		mounted() {
			if (this.playAnimation) {
				const { topLine, bottomLine } = this.$refs;

				gsap.set(topLine, { x: 0 });
				gsap.set(bottomLine, { x: 0 });

				this.timeline.repeatDelay(0.5);
				
				this.timeline.to(topLine, { duration: 1, x: "50%" }, 0);
				this.timeline.to(topLine, { duration: 1, x: 0 }, 1);
				
				this.timeline.to(bottomLine, { duration: 1, x: "-50%" }, 0);
				this.timeline.to(bottomLine, { duration: 1, x: 0 }, 1);
			}
		},
		methods: {
			entryAnimation() {
				const {lines} = this.$refs;
				gsap.set(lines.children, {scale: 0, transformOrigin: "50% 50%"});
				gsap.to(lines.children, {scale: 1, duration: 0.5, onComplete: this.maybePlayTimeline});
			}
		}
	};
</script>
