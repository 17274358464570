<template>
  <section class="photo-grid-section">
    <div class="container">
      <h2>Expand possible.</h2>
      <p>Our new tagline expresses our goal. To provide opportunities that build confidence, inspire individuality, and catalyze creativity. With passion and determination, student learning will include embracing the unknown and making a difference doing so.</p>
    </div>

    <div class="photo-grid-container">

			<div class="image-wrapper two-third mobile-full order-one">
				<img src="@/assets/images/Photo 1-sign@2x.png" alt="" />
			</div>

      <div class="svg-grid one-third mobile-half order-two">
        <TwoHalfCirclesSVG :play-animation="true" :foreground="foregrounds.sky" />
        <TwoHalfCirclesSVG :play-animation="true" :foreground="foregrounds.caterpillar" />
        <TwoHalfCirclesSVG :play-animation="true" :foreground="foregrounds.caterpillar" />
        <TwoHalfCirclesSVG :play-animation="true" :foreground="foregrounds.sky" />
				<div class="mobile-only">
					<TwoHalfCirclesSVG :play-animation="true" :foreground="foregrounds.sky" />
				</div>
				<div class="mobile-only">
					<TwoHalfCirclesSVG :play-animation="true" :foreground="foregrounds.caterpillar" />
				</div>
      </div>

      <div class="text-content two-third mobile-full">
        <p>"The look of this new brand is super cool. The logo, the colours and that hoodie are all awesome."</p>
        <p>Grade 6 Student</p>
      </div>

			<div class="image-wrapper one-third mobile-half order-three">
				<img src="@/assets/images/photo 5- water bottle@2x.png" alt="" />
			</div>

      <div class="svg-grid sky one-third mobile-half order-last">
        <StarSVG :play-animation="true" :foreground="foregrounds.caterpillar" />
        <ThreeHorizonLinesSVG :play-animation="false" :foreground="foregrounds.cobalt" />
        <DiamondSVG :play-animation="false" :foreground="foregrounds.cobalt" />
        <CircleSVG :play-animation="true" :foreground="foregrounds.persimmon" />
      </div>

			<div class="image-wrapper one-third mobile-full">
				<img src="@/assets/images/photo 3- hoodie@2x.png" alt="" />
			</div>

			<div class="image-wrapper one-third mobile-half">
				<img src="@/assets/images/photo 4- kcs crest@2x.png" alt="" />
			</div>

    </div> <!-- photo-grid-container -->

    <Scroller />

    <div class="photo-grid-container">

			<div class="image-wrapper one-third mobile-half order-last lamp-post">
				<img src="@/assets/images/Photo2-lamp-post@2x.png" alt="" />
			</div>

      <div class="text-content two-third mobile-full order-one">
        <p>"The new brand material looks amazing! I’m excited to see the brand reflected across the school and community.”</p>
        <p>KCS Teacher</p>
      </div>

			<div class="image-wrapper one-half hat">
				<img src="@/assets/images/photo 6- hats@2x.png" alt="" />
			</div>

			<div class="image-wrapper one-half mobile-full order-two">
				<img src="@/assets/images/photo7- book@2x.png" alt="" />
			</div>
    </div> <!-- photo-grid-container -->

  </section>
</template>

<script>
  import { branding } from "@/mixins/brand";
  import TwoHalfCirclesSVG from "./svg-blocks/TwoHalfCirclesSVG";
  import StarSVG from "./svg-blocks/StarSVG";
  import ThreeHorizonLinesSVG from "./svg-blocks/ThreeHorizontalLinesSVG";
  import DiamondSVG from "./svg-blocks/DiamondSVG";
  import CircleSVG from "./svg-blocks/CircleSVG";
	import Scroller from "@/components/Scroller";
  export default {
    name: "PhotoGrid",
    components: { Scroller, CircleSVG, DiamondSVG, ThreeHorizonLinesSVG, StarSVG, TwoHalfCirclesSVG },
    mixins: [branding]
  };
</script>

<style lang="scss">
  @import "~@/assets/scss/variables";

  .photo-grid-section {
    .container {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 40px;
      max-width: 788px;
			padding: 186px 0;

      h2 {
        font-size: 70px;
        font-weight: 600;
        line-height: 83px;
        color: #273084;
        margin: 0;
      }

      @media screen and (max-width: $md) {
        width: 95%;
        margin: 0 auto;
        text-align: left;
        gap: 10px;
        padding: 80px 15px;

        h2 {
          font-size: 50px;
          line-height: 58px;
        }
      }
    }
  }

  .photo-grid-container {
    display: flex;
		align-items: flex-start;
		justify-content: flex-start;
    flex-wrap: wrap;

    .one-third {
      width: calc(100%/3);
    }

    .two-third {
      width: calc(100%/3 * 2);
    }

    .one-half {
      width: 50%;
    }

    @media screen and (max-width: $md) {
      .mobile-full {
        width: 100%;
      }

      .mobile-half {
        width: 50%;
      }

      .order-one {
        order: -1;
      }

      .order-two {
        order: -1;
      }

      .order-three {
        order: -1;
      }

      .order-last {
        order: 1;
      }

			.lamp-post, .hat {
				height: 40vh;
			}

    }

		.image-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

    .svg-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
			background-color: $ultramarine;

      svg {
        background-color: $ultramarine;
      }

			.svg-two-half-circles {
				&:nth-child(3), &:nth-child(4) {
					transform: rotate(180deg);
				}
			}

      &.sky svg {
        background-color: $sky;
      }
    }

    .text-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10%;
      max-height: 100vh;
			align-self: stretch;

      @media screen and (max-width: $md) {
        padding: 80px 20px;
      }

      p:first-of-type {
        font-size: 27px;
        font-weight: 600;
        line-height: 34px;

        @media screen and (max-width: $md) {
          font-size: 20px;
          line-height: 25px;
        }
      }
    }
  }

  .respect-manners-try {
    background-color: $caterpillar;
  }
</style>
