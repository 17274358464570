<template>
	<div id="app">
		<Hero />
		<BrandVideo />
		<FourDoors />
		<KCSAnimatedLogo />
		<SchoolRules />
		<DerekMessage />
		<PhotoGrid />
		<ElementPlayground />
		<ShareTheNews />
	</div>
</template>

<script>
	import Hero from "@/components/Hero";
	import BrandVideo from "@/components/BrandVideo";
	import FourDoors from "@/components/FourDoors";
	import KCSAnimatedLogo from "@/components/KCSAnimatedLogo";
	import DerekMessage from "@/components/DerekMessage";
	import PhotoGrid from "@/components/PhotoGrid";
	import ElementPlayground from "@/components/ElementPlayground";
	import ShareTheNews from "@/components/ShareTheNews";
	import { branding } from "@/mixins/brand";
	import SchoolRules from "@/components/SchoolRules";

	export default {
		name: "App",
		mixins: [branding],
		components: {
			SchoolRules,
			ShareTheNews,
			ElementPlayground,
			PhotoGrid,
			DerekMessage,
			KCSAnimatedLogo,
			FourDoors,
			BrandVideo,
			Hero
		}
	};
</script>

<style lang="scss">

	@import "~@/assets/scss/variables";

	* {
		font-family: "Cera", sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		box-sizing: border-box;
	}

	#app {
		transition: all .25s linear;
	}

	body {
		margin: 0;
		padding: 0;
		color: $graphite;
		background: $library;
	}

	section {
    overflow-x: hidden;
    font-size: 18px;
		line-height: 23px;
		
    p {
			font-size: 18px;
      line-height: 23px;
    }

    @media screen and (max-width: $md) {
			font-size: 16px;
			line-height: 20px;
	
			p {
				font-size: 16px;
				line-height: 20px;
      }
    }
	}

  .container {
    padding: 80px 15px;
    max-width: 1200px;
    margin: 0 auto;

    @media screen and (max-width: $lg) {
      padding: 0 15px;
    }
  }

	.svg-shape {
		transition: all 0.2s linear;
		padding-bottom: 100%;
		width: 100%;
		position: relative;
		svg {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

  .section-text {
    h2 {
      font-size: 50px;
      font-weight: 500;
      line-height: 58px;

      @media screen and (max-width: $md) {
        font-size: 30px;
        line-height: 37px;
      }
    }
  }

  .mobile-only {
    display: none;

    @media screen and (max-width: $md) {
      display: block;
    }
  }

  .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: "";
  }

</style>
