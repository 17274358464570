<template>
	<div class="playground-frame frame-2">
		
		<div>
			<AnimatedSVG :play-animation="true" :active-shape="!beenSeen ? 'TwoSmiles' : null" :active-colour="foregrounds.persimmon" :should-stop="true" />
			<AnimatedSVG :play-animation="true" :active-shape="!beenSeen ? 'Diamond' : null" :active-colour="foregrounds.caterpillar" :should-stop="true" />
			<AnimatedSVG :play-animation="true" :active-shape="`TwoVerticalArrows`" :active-colour="foregrounds.turf" :active-background-colour="foregrounds.caterpillar" :should-stop="false" />
			<AnimatedSVG :play-animation="true" :active-shape="!beenSeen ? 'Star' : null" :active-colour="foregrounds.cobalt" :should-stop="true" />
		</div>
		
		<div>
			<AnimatedSVG :play-animation="true" :active-shape="!beenSeen ? 'Circle' : null" :active-colour="foregrounds.persimmon" :should-stop="true" />
			<AnimatedSVG :play-animation="true" :active-shape="`EightHorizontalArrows`" :active-colour="foregrounds.cobalt" :active-background-colour="foregrounds.turf" :should-stop="false" />
			<AnimatedSVG :play-animation="true" :active-shape="!beenSeen ? 'ThreeHorizontalLines' : null" :active-colour="foregrounds.turf" :should-stop="true" />
			<div class="message"><p>Expand<br/>Learning</p></div>
		</div>
		
	</div>
</template>

<script>
import AnimatedSVG from "@/components/AnimatedSVG";
import { branding } from "@/mixins/brand";

export default {
	name: "Frame2",
	components: { AnimatedSVG },
	props: {
		beenSeen: {
			type: Boolean,
			default: false
		},
		
	},
	mixins: [branding]
};
</script>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";
.frame-2 {
	grid-template-columns: repeat(2, 1fr);
	grid-auto-flow: row;
	@media screen and (max-width: $sm) {
		grid-template-columns: repeat(1, 1fr);
	}
	> div {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
	.svg-eight-horizontal-arrows {
		transform: rotate(-90deg);
	}
}
</style>