<template>
	<Transition appear @enter="onEnter" @before-leave="onLeave" mode="out-in">
		<div class="svg-shape svg-checkerboard" :style="{ background }">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
				<g ref="squares">
					<path ref="bottomSquare" d="M250,0H0V249H250Z" :fill="foreground" />
					<path ref="topSquare" d="M500,249H250V500H500Z" :fill="foreground" />
				</g>
			</svg>
		</div>
	</Transition>
</template>

<script>
	import { animatedSvg } from "@/mixins/animated-svg";
	import {gsap} from "gsap";
	
	export default {
		name: "CheckerboardSVG",
		mixins: [animatedSvg],
		mounted() {
			if (this.playAnimation) {
				const { bottomSquare, topSquare } = this.$refs;

				this.timeline.repeatDelay(1);
				
				this.timeline.to(bottomSquare, { duration: 1, x: "100%" }, 0);
				this.timeline.to(bottomSquare,{ duration: 1, y: "100%" }, 1);
				
				this.timeline.to(topSquare,{ duration: 1, x: "-100%"}, 0);
				this.timeline.to(topSquare,{duration: 1, y: "-100%"}, 1);
			}
		},
		methods: {
			entryAnimation() {
				const {squares} = this.$refs
				gsap.set(squares.children, {scale: 0, transformOrigin: "50% 50%"});
				gsap.to(squares.children, {scale: 1, duration: 0.5, onComplete: this.maybePlayTimeline})
			}
		}
	};
</script>
