<template>
	<Transition appear @enter="onEnter" @before-leave="onLeave" mode="out-in">
		<div class="svg-shape svg-eight-horizontal-arrows" :style="{ background }">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
				<g ref="arrows">
					<path
						d="M0,0V250L125,125Z"
						transform="translate(-125, 0)"
						:fill="foreground" />
					<path
						d="M0,250V500L125,375Z"
						transform="translate(-125, 0)"
						:fill="foreground" />

					<path d="M0,0V250L125,125Z" :fill="foreground" />
					<path d="M0,0V250L125,125Z" :fill="foreground" />
					<path d="M125,0V250L250,125Z" :fill="foreground" />
					<path d="M250,0V250L375,125Z" :fill="foreground" />
					<path d="M375,0V250L500,125Z" :fill="foreground" />
					<path d="M0,250V500L125,375Z" :fill="foreground" />
					<path d="M125,250V500L250,375Z" :fill="foreground" />
					<path d="M250,250V500L375,375Z" :fill="foreground" />
					<path d="M375,250V500L500,375Z" :fill="foreground" />
				</g>
			</svg>
		</div>
	</Transition>
</template>

<script>
	import { animatedSvg } from "@/mixins/animated-svg";
	import { gsap, Linear } from "gsap";

	export default {
		name: "EightHorizontalArrowsSVG",
		mixins: [animatedSvg],
		mounted() {
			if (this.playAnimation) {
				const { arrows } = this.$refs;
				this.timeline.to(arrows, { duration: 0.5, x: "20%", ease: Linear.easeNone });
			}
		},
		methods: {
			entryAnimation() {
				const {arrows} = this.$refs;
				gsap.set(arrows.children, {scale: 0, transformOrigin: "100% 50%"});
				gsap.to(arrows.children, {scale: 1, duration: 0.5, stagger: 0.02, onComplete: this.maybePlayTimeline})
			}
		}
	};
</script>
