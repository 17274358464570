<template>
	<Transition appear @enter="onEnter" @before-leave="onLeave" mode="out-in">
		<div class="svg-shape svg-star" :style="{ background }">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
				<g ref="svgStar">
					<rect
						class="rect"
						x="188"
						width="124"
						height="500"
						:fill="foreground" />
					<rect
						class="rect"
						y="188"
						width="500"
						height="124"
						:fill="foreground" />
					<rect
						class="rect"
						x="187.57"
						y="-0.11"
						width="124"
						height="500"
						transform="translate(-103.6 249.67) rotate(-45)"
						:fill="foreground" />
					<rect
						class="rect"
						x="-0.42"
						y="187.6"
						width="500"
						height="124"
						transform="translate(-103.39 249.59) rotate(-45)"
						:fill="foreground" />
				</g>
			</svg>
		</div>
	</Transition>
</template>

<script>
	import { animatedSvg } from "@/mixins/animated-svg";
	import { gsap, Linear } from "gsap";

	export default {
		name: "StarSVG",
		mixins: [animatedSvg],
		mounted() {
			if (this.playAnimation) {
				const { svgStar } = this.$refs;
				gsap.set(svgStar, { rotation: 0, transformOrigin: "50% 50%" });
				gsap.set(svgStar.children, {scale: 0, transformOrigin: "50% 50%"});
				
				this.timeline.to(svgStar, { duration: 1, rotation: -45, ease: Linear.easeNone });
				this.timeline.to(svgStar, { duration: 0.5, scale: 0.95, ease: Linear.easeNone }, 0);
				this.timeline.to(svgStar, { duration: 0.5, scale: 1, ease: Linear.easeNone }, 0.5);
			}
		},
		methods: {
			entryAnimation() {
				const { svgStar } = this.$refs;
				gsap.to(svgStar.children, {scale: 1, duration: 0.5, stagger: 0.05, onComplete: this.maybePlayTimeline});
			}
		}
	};
</script>
