<template>
	<Transition appear @enter="onEnter" @before-leave="onLeave" mode="out-in">
		<div class="svg-shape svg-circle" :style="{ background }">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
				<g ref="circle">
					<path
						style="transform-origin: center"
						d="M500,250C500,111.9,388.1,0,250,0S0,111.9,0,250,111.9,500,250,500,500,388.1,500,250Z"
						:fill="foreground" />
					<path
						style="transform-origin: center; transform: translateX(100%)"
						d="M500,250C500,111.9,388.1,0,250,0S0,111.9,0,250,111.9,500,250,500,500,388.1,500,250Z"
						:fill="foreground" />
				</g>
			</svg>
		</div>
	</Transition>
</template>

<script>
	import { animatedSvg } from "@/mixins/animated-svg";
	import {gsap} from "gsap";
	
	export default {
		name: "CircleSVG",
		mixins: [animatedSvg],
		mounted() {
			if (this.playAnimation) {
				const { circle } = this.$refs;
				gsap.set(circle.children, {scale: 0})
				this.timeline.to(circle, { duration: 1.1, x: "-50%" });
			}
		},
		methods: {
			entryAnimation(){
				const {circle} = this.$refs;
				gsap.to(circle.children, {scale: 1, duration: 0.5, onComplete: this.maybePlayTimeline});
			}
		}
	};
</script>
