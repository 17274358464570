<template>
  <section class="element-playground">
		<div>

			<h2><img src="@/assets/svg/info-icon.svg" width="20" height="20" alt="" /> Tap to play with our elements</h2>

			<div class="frames-container">
				<button class="playground-btn" @click="changeFrame">
					<component :is="`Frame${currentFrame + 1}`" :been-seen="beenSeen" />
				</button>
			</div>

			<p class="count">{{ currentFrame + 1 }}/{{ frames }}</p>

		</div>
  </section>
</template>

<script>

import Frame1 from "@/components/element-playground-frames/Frame1";
import Frame2 from "@/components/element-playground-frames/Frame2";
import Frame3 from "@/components/element-playground-frames/Frame3";
import Frame4 from "@/components/element-playground-frames/Frame4";
import Frame5 from "@/components/element-playground-frames/Frame5";
import Frame6 from "@/components/element-playground-frames/Frame6";
import { branding } from "@/mixins/brand";

export default {

  name: "ElementPlayground",
	components: { Frame1, Frame2, Frame3, Frame4, Frame5, Frame6 },
	data() {
		return {
			currentFrame: 0,
			frames: 6,
			beenSeen: false,
		};
	},
	mixins: [branding],
	methods: {
		changeFrame() {
			// Check to see if we're at the end
			// This means the user has seen all frames, so we can switch up the shapes on the next go around
			if(this.currentFrame === (this.frames - 1)) {
				this.beenSeen = true;
			}

			this.currentFrame = (this.currentFrame + 1) % this.frames;
		}
	}
};
</script>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";

.element-playground {
	text-align: center;
	padding: 230px 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
  @media screen and (max-width: $lg) {
    padding: 80px 20px;
  }
	> div {
		width: 100%;
	}
	h2 {
		font-size: 18px;
		font-weight: 500;
		margin-bottom: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		img {
			margin-right: 5px;
		}
	}
	.count {
		margin-top: 50px;
		font-size: 18px;
		font-weight: 400;
	}
	.message {
		display: flex;
		align-items: center;
		justify-content: center;
		p {
			font-size: 27px;
			font-weight: 500;
			line-height: 1.23;
			color: $graphite;
		}
	}
}

.frames-container {
	width: 100%;
	max-width: $lg;
	margin: 0 auto;
}

.playground-btn {
	width: 100%;
	cursor:pointer;
	padding: 0;
	border: none;
	background: transparent;
}

.playground-frame {
	width: 100%;
	display: grid;
}
</style>
