<template>
	<Transition appear @enter="onEnter" @before-leave="onLeave" mode="out-in">
		<div class="svg-shape svg-diamond" :style="{ background }">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
				<g ref="diamond" transform="translate(-570 -567)">
					<rect
						x="-434.42"
						y="-431.09"
						width="655.06"
						height="655.06"
						transform="translate(612.01 460.84) rotate(-45)"
						:fill="foreground" />
					<rect
						x="279.36"
						y="-431.09"
						width="655.06"
						height="655.06"
						transform="translate(821.07 965.56) rotate(-45)"
						:fill="foreground" />
					<rect
						x="279.36"
						y="276.03"
						width="655.06"
						height="655.06"
						transform="translate(321.06 1172.67) rotate(-45)"
						:fill="foreground" />
					<rect
						x="-434.42"
						y="276.03"
						width="655.06"
						height="655.06"
						transform="translate(112 667.95) rotate(-45)"
						:fill="foreground" />
				</g>
			</svg>
		</div>
	</Transition>
</template>

<script>
	import { animatedSvg } from "@/mixins/animated-svg";
	import { gsap } from "gsap";

	export default {
		name: "DiamondSVG",
		mixins: [animatedSvg],
		mounted() {
			if (this.playAnimation) {
				const { diamond } = this.$refs;

				gsap.set(diamond, { transformOrigin: "50% 50%" });
				gsap.set(diamond.children, {scale: 0, transformOrigin: "50% 50%"});
				
				this.timeline.repeatDelay(1);
				this.timeline.to(diamond, { duration: 0.5, rotation: -90 });
			}
		},
		methods: {
			entryAnimation() {
				const {diamond} = this.$refs;
				gsap.to(diamond.children, {scale: 1, duration: 0.5, onComplete: this.maybePlayTimeline});
			}
		}
	};
</script>
