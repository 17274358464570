<template>
  <section ref="section" class="three-rules-section">

<!--		<div ref="container" class="container">-->

		<div ref="pin" class="pin">

			<div class="container">

				<div class="rules-heading">
					<h2>Three simple rules we all live by at school and in life.</h2>
				</div>

				<div ref="sectionText" class="section-text">
					<p>Respect, Manners and Try Your Best. Our staff, students, parents and alumni families all know these three essential school rules define who we are, and what we value above all else. Our school rules ensure our students are good citizens of the world.</p>

				</div> <!-- section-text -->

				<div class="rules-container">

					<div ref="rule1" class="rule rule-1">
						<img src="@/assets/svg/respect.svg" width="150" alt="Manners Icon" />
						<div>
							<h3>Respect</h3>
							<p>Respect is symbolized by the familiar two-line sign representing equality.</p>
						</div>
					</div>

					<div ref="rule2" class="rule">
						<img src="@/assets/svg/manners.svg" width="150" alt="Manners Icon" />
						<div>
							<h3>Manners</h3>
							<p>Two curved shapes represent two people and form the shape of a smile.</p>
						</div>
					</div>

					<div ref="rule3" class="rule">
						<img src="@/assets/svg/try-your-best.svg" width="150" alt="Try Your Best Icon" />
						<div>
							<h3>Try Your Best</h3>
							<p>Arrows point forward and upward to represent doing the best you can.</p>
						</div>
					</div>

				</div> <!-- rules-container -->

			</div>

		</div>

<!--		</div> &lt;!&ndash; container &ndash;&gt;-->

  </section>
</template>

<script>
import { branding } from "@/mixins/brand";

import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "SchoolRules",
	mixins: [branding],
	mounted() {
		const {rule1, rule2, rule3} = this.$refs;

		ScrollTrigger.matchMedia({

			// large
			"(min-width: 1086px)": () => {

				gsap.set(rule1, {opacity: 1});
				gsap.set([rule2, rule3], {opacity: 0});

				// setup animations and ScrollTriggers for screens 960px wide or greater...
				// These ScrollTriggers will be reverted/killed when the media query doesn't match anymore.
				ScrollTrigger.create({
					trigger: ".pin",
					start: "top top",
					end: "+=2500s",
					// markers: true,
					pin: true,
					pinSpacing: true,
				});

				ScrollTrigger.create({
					trigger: rule1,
					start: '0s',
					end: '+=500s',
					toggleActions: 'play none reverse reverse',
					animation: gsap.to(rule1, {duration: 0.2, opacity: 0})
				});

				ScrollTrigger.create({
					trigger: rule2,
					start: '500s',
					end: '+=1000s',
					toggleActions: 'play reverse play reverse',
					animation: gsap.to(rule2, {duration: 0.2, opacity: 1})
				});

				ScrollTrigger.create({
					trigger: rule3,
					start: '1500s',
					end: '+=1000s',
					toggleActions: 'play reverse play reverse',
					animation: gsap.to(rule3, {duration: 0.2, opacity: 1})
				});

			},
			"(max-width: 1085px)": () => {
				gsap.set([rule1, rule2, rule3], {opacity: 1});
			}

		});

	}
};
</script>

<style lang="scss">

@import "~@/assets/scss/_variables.scss";

.pin {
	height: 100vh;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	@media screen and (max-width: $container) {
		height: auto;
	}
}

.three-rules-section {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	.container {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		margin: 0 auto;
		max-width: $container;

		@media screen and (max-width: $container) {
			justify-content: center;
			padding: 80px 30px;
		}

	}

	.section-text {
		flex-basis: 50%;
		@media screen and (max-width: $container) {
			flex-basis: 100%;
		}
	}

	.rules-heading {
		flex-basis: 100%;

		h2 {
			font-weight: 500;
			font-size: 50px;
			line-height: 58px;
			width: 100%;
			max-width: 45%;
			margin: 0 0 20px 0;

			@media screen and (max-width: $container) {
				max-width: 100%;
			}

			@media screen and (max-width: $md) {
				font-size: 30px;
				line-height: 38px;
			}

		}
	}

	.rules-container {
		position: relative;
		flex-basis: 50%;
		padding-left: 100px;

		@media screen and (max-width: $container) {
			flex-basis: 70%;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			padding: 0 20px;
			flex-direction: column;
			margin-top: 100px;
		}

		.rule {
			display: flex;
			opacity: 0;
			position: absolute;
			align-items: flex-start;
			justify-content: flex-start;

			@media screen and (max-width: $container) {
				opacity: 1;
				position: relative;
				margin-bottom: 60px;
			}

			@media screen and (max-width: $md) {
				align-items: center;
				flex-direction: column;
				justify-content: center;
				text-align: center;
				margin-bottom: 40px;
			}

			> div {
				padding-left: 40px;
				margin-top: -5px;
				@media screen and (max-width: $md) {
					padding-left: 0;
					margin-top: 20px;
				}
			}

      h3 {
				font-size: 18px;
				line-height: 23px;
				font-weight: 600;
				margin-top: 0;
      }

		}


	}

}

</style>
