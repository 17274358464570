<template>
	<Transition appear @enter="onEnter" @before-leave="onLeave" mode="out-in">
		<div class="svg-shape svg-two-smiles" :style="{ background }">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
				<g ref="curves">
					<g ref="rightCurves">
						<path
							ref="curveRight"
							d="M398.1,274A151.72,151.72,0,0,1,273.9,398.2V500c119.3-11.8,214.1-106.7,226-226Z"
							:fill="foreground" />
						<path
							ref="curveRight"
							d="M398.1,0a151.89,151.89,0,0,1-42.3,81.9,152.22,152.22,0,0,1-81.9,42.3V226C393.3,214.2,488.1,119.3,500,0Z"
							:fill="foreground" />
					</g>
					<g ref="leftCurves">
						<path
							ref="curveLeft"
							d="M226,398.1A151.72,151.72,0,0,1,101.8,273.9H0C11.8,393.2,106.7,488,226,499.9Z"
							:fill="foreground" />
						<path
							ref="curveLeft"
							d="M226,124.1a151.89,151.89,0,0,1-81.9-42.3A151.61,151.61,0,0,1,101.8,0H0C11.8,119.3,106.7,214.1,226,226Z"
							:fill="foreground" />
					</g>
				</g>
			</svg>
		</div>
	</Transition>
</template>

<script>
	import { animatedSvg } from "@/mixins/animated-svg";
	import { gsap } from "gsap";

	export default {
		name: "TwoSmilesSVG",
		mixins: [animatedSvg],
		mounted() {
			if (this.playAnimation) {
				const { leftCurves, rightCurves } = this.$refs;

				gsap.set(rightCurves.children, {
					transformOrigin: "left -35px top -35px"
				});
				gsap.set(leftCurves.children, {
					transformOrigin: "right -35px top -35px"
				});

				this.timeline.repeatDelay(1);
				
				this.timeline.to(leftCurves.children, { duration: 0.5, rotation: -45 }, 0);
				this.timeline.to(leftCurves.children, { duration: 0.5, rotation: 0 }, 0.5);
				
				this.timeline.to(rightCurves.children, { duration: 0.5, rotation: 45 }, 0);
				this.timeline.to(rightCurves.children, { duration: 0.5, rotation: 0 }, 0.5);
			}
		},
		methods: {
			entryAnimation(){
				const { curves } = this.$refs;
				gsap.set(curves.children, {scale: 0, transformOrigin: "50% 50%"})
				gsap.to(curves.children, {scale: 1, duration: 0.5, onComplete: this.maybePlayTimeline});
			}
		}
	};
</script>
