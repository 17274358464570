<template>
	<Transition appear @enter="onEnter" @before-leave="onLeave" mode="out-in">
		<div class="svg-shape svg-two-vertical-arrows" :style="{ background }">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
				<g ref="arrows">
					<path d="M-.5,500h500l-250-251.9Z" :fill="foreground" />
					<path d="M-.5,248.1h500L249.5,0Z" :fill="foreground" />
					<path
						d="M-.5,500h500l-250-251.9Z"
						transform="translate(0, 500)"
						:fill="foreground" />
					<path
						d="M-.5,248.1h500L249.5,0Z"
						transform="translate(0, 500)"
						:fill="foreground" />
				</g>
			</svg>
		</div>
	</Transition>
</template>

<script>
	import { animatedSvg } from "@/mixins/animated-svg";
	import { gsap, Linear } from "gsap";

	export default {
		name: "TwoVerticalArrowsSVG",
		mixins: [animatedSvg],
		mounted() {
			if (this.playAnimation) {
				const { arrows } = this.$refs;
				this.timeline.to(arrows, { duration: 1, y: "-50%", ease: Linear.easeNone });
			}
		},
		methods: {
			entryAnimation() {
				const {arrows} = this.$refs;
				gsap.set(arrows.children, {transformOrigin: "50% 0%", scale: 0});
				gsap.to(arrows.children, {scale: 1, duration: 0.5, stagger: 0.1, onComplete: this.maybePlayTimeline});
			}
		}
	};
</script>
