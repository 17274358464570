<template>
	<Transition appear @enter="onEnter" @before-leave="onLeave" mode="out-in">
		<div class="svg-shape svg-one-smile" :style="{ background }">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
				<g ref="curves">
					<path
						ref="curveRight"
						d="M398.6,137A151,151,0,0,1,275,260.6V362c118.8-11.8,213.2-106.2,225-225Z"
						:fill="foreground" />
					<path
						ref="curveLeft"
						d="M225,260.6A151,151,0,0,1,101.4,137H0C11.8,255.8,106.2,350.2,225,362Z"
						:fill="foreground" />
				</g>
			</svg>
		</div>
	</Transition>
</template>

<script>
	import { animatedSvg } from "@/mixins/animated-svg";
	import { gsap } from "gsap";

	export default {
		name: "OneSmileSVG",
		mixins: [animatedSvg],
		mounted() {
			if (this.playAnimation) {
				const { curveRight, curveLeft } = this.$refs;

				gsap.set(curveRight, { transformOrigin: "left -35px top -35px", scale: 0 });
				gsap.set(curveLeft, { transformOrigin: "right -35px top -35px", scale: 0 });
				
				this.timeline.repeatDelay(1);
				
				this.timeline.to(curveLeft, { duration: 1, rotation: -45 }, 0);
				this.timeline.to(curveLeft, { duration: 1, rotation: 0 }, 1);
				
				this.timeline.to(curveRight, { duration: 1, rotation: 45 }, 0);
				this.timeline.to(curveRight, { duration: 1, rotation: 0 }, 1);
			}
		},
		methods: {
			entryAnimation(){
				const { curves } = this.$refs;
				gsap.to(curves.children, {scale: 1, duration: 0.5, onComplete: this.maybePlayTimeline});
			}
		}
	};
</script>
