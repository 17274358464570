export const branding = {
	data() {
		return {
			foregrounds: {
				cobalt: "#273084",
				persimmon: "#FF5729",
				ultramarine: "#0869D9",
				caterpillar: "#E2E42B",
				sky: "#47C2D4",
				turf: "#01AB82"
			},
			backgrounds: {
				ink: "#161B48",
				shale: "#BFB7B7",
				library: "#FFF5EA",
				graphite: "#232323"
			}
		};
	},
	methods: {
		getRandomForegroundColour() {
			const keys = Object.keys(this.foregrounds);
			return this.foregrounds[keys[Math.floor(Math.random() * keys.length)]];
		},
		getRandomBackgroundColour() {
			const keys = Object.keys(this.backgrounds);
			return this.backgrounds[keys[Math.floor(Math.random() * keys.length)]];
		}
	}
}
