<template>
  <section class="share-news-section">
    <div class="background-shape top-shape">
      <StarSVG :play-animation="false" :foreground="foregrounds.cobalt" />
    </div>

    <div class="container">
      <div class="text-content">
        <h2>Share the news!</h2>
        <p>Whether you’re a student, staff, parent, alumni or member of our extended KCS community, you can help us spread the word about our new KCS brand! Please click on the links to the right and share our message on social media.</p>
      </div>

      <div class="social-links">
        <div>
          <a :href="`https://twitter.com/intent/tweet?text=An%20exciting%20new%20chapter%20begins%20at%20KCS.%20We%20are%20now%20a%20full%20JK%20to%20grade%2012%20school.%20Find%20out%20more%20today.%0A%0AKCS.%0A%0AExpand%20possible.%0A%0A${url}`" target="_blank" rel="noopener">Share on Twitter</a>
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 65 65" fill="none">
            <rect width="65" height="65" fill="#14AB7D"/>
            <path d="M50 33H11" stroke="#273084" stroke-width="3"/>
            <path d="M42.5 44L42.5 21L53.5 32.5L42.5 44Z" fill="#273084"/>
          </svg>
        </div>

        <div>
          <a :href="`https://www.facebook.com/sharer/sharer.php?u=${url}&quote=An%20exciting%20new%20chapter%20begins%20at%20KCS.%20We%20are%20now%20a%20full%20JK%20to%20grade%2012%20school.%20Find%20out%20more%20today.%0A%0AKCS.%0A%0AExpand%20possible.`" target="_blank" rel="noopener">Share on Facebook</a>
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 65 65" fill="none">
            <rect width="65" height="65" fill="#14AB7D"/>
            <path d="M50 33H11" stroke="#273084" stroke-width="3"/>
            <path d="M42.5 44L42.5 21L53.5 32.5L42.5 44Z" fill="#273084"/>
          </svg>
        </div>

          <div>
            <a :href="`https://www.linkedin.com/sharing/share-offsite/?url=${url}`" target="_blank" rel="noopener">Share on Linkedin</a>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 65 65" fill="none">
              <rect width="65" height="65" fill="#14AB7D"/>
              <path d="M50 33H11" stroke="#273084" stroke-width="3"/>
              <path d="M42.5 44L42.5 21L53.5 32.5L42.5 44Z" fill="#273084"/>
            </svg>
          </div>
      </div>
    </div>

    <div class="website-link">
      <a href="http://www.kcs.ca/" target="_blank" rel="noopener"><span>Visit KCS.ca</span><TwoVerticalArrowsSVG v-for="n in 3" :key="n" :play-animation="false" :foreground="foregrounds.turf" /></a>
    </div>

    <div class="background-shape">
      <StarSVG :play-animation="false" :foreground="foregrounds.cobalt" />
    </div>
  </section>
</template>

<script>
import { branding } from "@/mixins/brand";
import StarSVG from "./svg-blocks/StarSVG";
import TwoVerticalArrowsSVG from "./svg-blocks/TwoVerticalArrowsSVG";

export default {
  name: "ShareTheNews",
  components: { TwoVerticalArrowsSVG, StarSVG },
  mixins: [branding],
	data() {
		return {
			url: null
		};
	},
	created() {
		this.url = encodeURI(window.location.href);
	}
};
</script>

<style lang="scss">
  @import "~@/assets/scss/variables";

  .share-news-section {
    position: relative;
    overflow-y: hidden;
		background: $ultramarine;
		color: $library;

    .background-shape {
      position: absolute;

      &:first-child {
        right: -220px;
				width: 480px;
      }

      &:last-child {
        bottom: -260px;
				width: 639px;
			}

      @media screen and (max-width: $lg) {

        &:first-child {
          right: -130px;
					width: 300px;
        }

        &:last-child {
          bottom: -110px;
					left: -10px;
					width: 300px;
				}

      }

      @media screen and (max-width: $sm) {

        &:first-child {
          right: -60px;
					width: 143px;
        }

        &:last-child {
          bottom: -40px;
					left: -10px;
					width: 215px;
				}

      }

    }

    .container {
      display: flex;
			max-width: 978px;
      justify-content: flex-start;
      align-items: flex-start;
			padding: 357px 30px 400px 30px;

      @media screen and (max-width: $lg) {
				max-width: 100%;
      }

      @media screen and (max-width: $md) {
        flex-direction: column;
				justify-content: center;
				padding: 200px 30px 200px 30px;
			}

      @media screen and (max-width: $sm) {
        padding: 100px 20px 200px 20px;
      }
    }

    .text-content {
      width: 100%;
      z-index: 9;
      color: $library;
			padding-right: 15%;

			@media screen and (max-width: $lg) {
				margin-bottom: 30px;
				width: 90%;
				padding-right: 5%
			}

      h2 {
        font-size: 50px;
        font-weight: 500;
        line-height: 58px;
        margin: 0 0 40px 0;

        @media screen and (max-width: $md) {
          font-size: 30px;
					margin-bottom: 0;
        }
      }
    }

    .social-links {
      display: flex;
      flex-direction: column;
			align-items: stretch;
			justify-content: center;
      z-index: 9;
			width: 300px;

      div {
        display: grid;
        grid-template-columns: 3fr 1fr;
				margin-bottom: 20px;
      }

			svg {
				width: 65px;
				height: 65px;
			}

      a {
        display: flex;
        padding: 20px;
        background-color: $library;
        color: $cobalt;
        text-decoration: none;
        position: relative;
        align-items: center;
        justify-content: center;
        font-weight: 500;
				font-size: 18px;
				white-space: nowrap;
      }
    }

    .website-link {
      position: absolute;
      bottom: 0;
      background-color: $cobalt;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      z-index: 9;

      a {
        color: $library;
        display: grid;
        grid-template-columns: 5fr 2fr 2fr 2fr;
        align-items: center;
        text-decoration: none;

        @media screen and (max-width: $sm) {
          width: 100%;
          grid-template-columns: 4fr 1fr 1fr 1fr;
        }

        span {
          margin-right: 20px;
					white-space: nowrap;
          font-weight: 500;
					text-align: center;
        }

        .svg-shape {
          transform: rotate(90deg);
        }
      }
    }
  }
</style>
