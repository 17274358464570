<template>
  <section class="four-doors-section">

    <div class="container">

      <div class="icons-container">

        <p class="info-text"><img src="@/assets/svg/info-icon.svg" width="21" height="21" alt="" /> Click on any icon to learn more</p>

        <div class="icons-grid">

          <div class="icon-door">
            <TwoVerticalArrowsSVG :play-animation="false" :foreground="foregrounds.ultramarine" />
            <button class="stretched-link" @click="setActiveSection('Academics')">Academics</button>

            <Transition appear name="scale-fade" mode="out-in">
              <div v-if="activeSection === 'Citizenship'" class="door-description citizenship">
                <p>Citizenship</p>
                <p>An octagram shape symbolizes maintaining strong community connections.</p>
              </div>
            </Transition>
          </div>


          <div class="icon-door">
            <CurvedDiamondSVG :play-animation="false" />
            <button class="stretched-link" @click="setActiveSection('Arts')">Arts</button>

            <Transition appear name="scale-fade" mode="out-in">
              <div v-if="activeSection === 'Athletics'" class="door-description athletics">
                <p>Athletics</p>
                <p>Three substantial vertical bars represent strength, resilience and teamwork.</p>
              </div>
            </Transition>
          </div>

          <div class="icon-door">
            <ThreeHorizonLinesSVG :play-animation="false" :foreground="foregrounds.persimmon" />
            <button class="stretched-link" @click="setActiveSection('Athletics')">Athletics</button>

            <Transition appear name="scale-fade" mode="out-in">
              <div v-if="activeSection === 'Arts'" class="door-description arts">
                <p>Arts</p>
                <p>An expanding shape within a frame represents expression and creativity.</p>
              </div>
            </Transition>
          </div>

          <div class="icon-door">
            <StarSVG :play-animation="false" :foreground="foregrounds.ultramarine" />
            <button class="stretched-link" @click="setActiveSection('Citizenship')">Citizenship</button>

            <Transition appear name="scale-fade" mode="out-in">
              <div v-if="activeSection === 'Academics'" class="door-description academics">
                <p>Academics</p>
                <p>Two upward arrows represent the partnership between school and student.</p>
              </div>
            </Transition>
          </div>

        </div> <!-- icons-grid -->

      </div> <!-- icons-container -->

      <div class="section-text">

        <h2>Four Doors.<br/> Infinite Destinations.</h2>

        <p>By encouraging our students to explore and value academics, arts, athletics and citizenship, we help them experience a deeper and richer approach to learning, both at the school and in the community.</p>

        <p>Every student has the opportunity to examine all aspects of school life, to take responsible risks and uncover a wealth of talents, interests, and passions throughout their journey.</p>

      </div> <!-- section-text -->

    </div> <!-- container -->

  </section>
</template>

<script>
  import TwoVerticalArrowsSVG from "./svg-blocks/TwoVerticalArrowsSVG";
  import ThreeHorizonLinesSVG from "./svg-blocks/ThreeHorizontalLinesSVG";
  import { branding } from "@/mixins/brand";
  import CurvedDiamondSVG from "./svg-blocks/CurvedDiamondSVG";
  import StarSVG from "./svg-blocks/StarSVG";


  export default {
  name: "FourDoors",
    components: { StarSVG, CurvedDiamondSVG, ThreeHorizonLinesSVG, TwoVerticalArrowsSVG },
    mixins: [branding],
    data() {
      return {
        activeSection: null
      }
    },
    methods: {
			setActiveSection(section) {
				if(section === this.activeSection) {
					this.activeSection = null;
				} else {
					this.activeSection = section;
				}
			}
    }
  };
</script>

<style lang="scss">
@import "~@/assets/scss/variables";


.scale-fade-enter-active {
	transition: all .2s cubic-bezier(1.0, 0.5, 0.5, 1.0);
	opacity: 1;
	transform: scale(1);
}

.scale-fade-leave-active {
	transition: all .2s cubic-bezier(1.0, 0.5, 0.5, 1.0);
	opacity: 0;
	transform: scale(0.8);
}

.scale-fade-enter, .scale-fade-leave {
	opacity: 0;
	transform: scale(0.8);
}

$ipad-pro: 1274px;

.four-doors-section {
  display: flex;
	padding: 230px 0;
	align-items: center;

  @media screen and (max-width: $container) {
    padding: 100px 0;
  }

	@media screen and (max-width: $sm) {
		padding: 50px 0;
	}

  .container {
    display: flex;
		max-width: $container;

		.section-text {
      margin-left: 120px;
			h2 {
				font-weight: 500;
				font-size: 50px;
				line-height: 58px;
				@media screen and (max-width: $md) {
					font-size: 30px;
					line-height: 37px;
				}
			}
		}

		@media screen and (max-width: $ipad-pro) {
			.section-text {
				margin-left: 80px;
			}
		}

    @media screen and (max-width: $lg) {
      flex-direction: column-reverse;

      .section-text {
        width: 95%;
        margin: 0 auto 60px;
      }
    }
  }

  .info-text {
    text-align: center;
    font-weight: 500;
    display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		img {
			margin-right: 15px;
		}
  }

  .icons-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 44px 100px;
    margin-top: 90px;

    @media screen and (max-width: $lg) {
      gap: 40px 10px;
      margin: 90px auto 80px;
      width: 100%;
      max-width: 480px;
    }

    @media screen and (max-width: $sm) {
      margin: 50px auto 0;
      width: 90%;
    }

    .icon-door {
      position: relative;
      width: 150px;
      margin: 0 auto;

      @media screen and (max-width: $sm) {
        width: 105px;
      }

      &:nth-child(3) {
        .svg-shape {
          transform: rotate(90deg);
        }
      }

      button {
        background: transparent;
        border: none;
        padding: 15px 0 0 0;
        font-size: 18px;
        cursor: pointer;
        width: 100%;
        margin: 0 auto;
        font-weight: 600;
        color: $graphite;
      }

      .door-description {
        position: absolute;
        width: 270px;
        height: 270px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: #263085;
        color: $library;
        padding: 20px;
				z-index: 9;
				border-radius: 500px;

				p {
					margin-bottom: 0;
				}

        p:first-of-type {
          font-weight: 700;
          margin: 0;
        }

				&.arts {
					top: -15px;
					right: -15px;
					border-top-right-radius: 0;
					transform-origin: top right;
				}
				&.academics {
					top: -15px;
					left: -25px;
					border-top-left-radius: 0;
					transform-origin: top left;
				}
        &.athletics {
          bottom: 0;
          left: -25px;
          border-bottom-left-radius: 0;
					transform-origin: bottom left;
        }
        &.citizenship {
          bottom: 0;
          right: -15px;
          border-bottom-right-radius: 0;
					transform-origin: bottom right;
        }

				@media screen and (max-width: $ipad-pro) {
					width: 230px;
					height: 230px;
				}

        @media screen and (max-width: $md) {
          width: 250px;
          height: 250px;

          p:last-of-type {
            font-size: 14px;
          }

          &.arts {
            top: -10px;
            right: -25px;
          }
          &.academics {
            top: -10px;
          }
          &.citizenship {
            right: -25px;
          }
        }

        @media screen and (max-width: $sm) {
          width: 180px;
          height: 180px;
        }
      }
    }
  }
}
</style>
