<template>
	<Transition appear @enter="onEnter" @before-leave="onLeave" mode="out-in">
		<div class="svg-shape svg-two-half-circles" :style="{ background }">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
				<g ref="halfCircles">
					<path
						d="M250,250C250,388.1,138.1,500,0,500V0C138.1,0,250,111.9,250,250Z"
						:fill="foreground" />
					<path
						d="M500,250c0,138.1-111.5,250-250,250V0C387.5,0,500,111.9,500,250Z"
						:fill="foreground" />
					<path
						d="M250,250C250,388.1,138.1,500,0,500V0C138.1,0,250,111.9,250,250Z"
						transform="translate(-500, 0)"
						:fill="foreground" />
					<path
						d="M500,250c0,138.1-111.5,250-250,250V0C387.5,0,500,111.9,500,250Z"
						transform="translate(-500, 0)"
						:fill="foreground" />
				</g>
			</svg>
		</div>
	</Transition>
</template>

<script>
	import { animatedSvg } from "@/mixins/animated-svg";
	import { gsap, Linear } from "gsap";

	export default {
		name: "TwoHalfCirclesSVG",
		mixins: [animatedSvg],
		mounted() {
			if (this.playAnimation) {
				const { halfCircles } = this.$refs;
				this.timeline.to(halfCircles, { duration: 1.5, x: "50%", ease: Linear.easeNone });
			}
		},
		methods: {
			entryAnimation() {
				const { halfCircles } = this.$refs;
				gsap.set(halfCircles.children, {transformOrigin: "50% 50%", scale: 0});
				gsap.to(halfCircles.children, {scale: 1, duration: 0.5, onComplete: this.maybePlayTimeline})
			}
		}
	};
</script>
