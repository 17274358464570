<template>
	<section class="hero">
		<div class="row">
			<div class="logo-container">
				<img src="@/assets/svg/logo.svg" alt="KCS Logo" width="200" />
			</div>
			<div class="top-svg-shapes">
				<AnimatedSVG
					v-for="(shape, n) in shapes.slice(0,4)" :key="n"
					:play-animation="true"
					:active-shape="shape.shape"
					:active-colour="shape.colour"
					:should-stop="((n === 0) || !randomIndexes.includes(n)) || innerWidth < 560"
				/>
			</div>
		</div>

		<div class="row">

			<div class="hero-message">
				<h1>An exciting new chapter in the KCS story.</h1>
        <p>Growing since 1989, KCS is now proud to inspire and empower students from JK right to grade 12.</p>
        <p>We’re building a diverse, engaged community of lifelong learners. Together, we will redefine what’s possible within our distinct culture of caring, respect, and curiosity.</p>
			</div>

			<div class="svg-icons">
				<AnimatedSVG
					v-for="(shape, n) in shapes.slice(4, shapes.length)" :key="n"
					:play-animation="true"
					:active-shape="shape.shape"
					:active-colour="shape.colour"
					:should-stop="!randomIndexes.includes(n)"
				/>
			</div>

		</div>
	</section>
</template>

<script>
import AnimatedSVG from "@/components/AnimatedSVG";
import { branding } from "@/mixins/brand";

export default {
	name: "Hero",
	mixins: [branding],
	components: { AnimatedSVG },
	data() {
		return {
			randomIndexes: [],
			innerWidth: 0,
		};
	},
	created() {
		this.innerWidth = window.innerWidth;
	},
	mounted() {
		this.fillRandomIndexes();

		setInterval(() => {
			this.fillRandomIndexes();
		}, 5000);
	},
	computed: {
		shapes() {
			return [
				{ shape: "DiagonalArrows", colour: this.foregrounds.persimmon },
				{ shape: "TwoMountains", colour: this.foregrounds.ultramarine },
				{ shape: "TwoHalfCircles", colour: this.foregrounds.cobalt },
				{ shape: "Decagon", colour: this.foregrounds.persimmon },

				{ shape: "CurvedDiamond", colour: this.foregrounds.turf },
				{ shape: "Checkerboard", colour: this.foregrounds.caterpillar },
				{ shape: "FourCircles", colour: this.foregrounds.cobalt },
				{ shape: "TwoHalfCircles", colour: this.foregrounds.caterpillar },
				{ shape: "TwoSmiles", colour: this.foregrounds.persimmon },
				{ shape: "Star", colour: this.foregrounds.ultramarine },
				{ shape: "EightHorizontalArrows", colour: this.foregrounds.ultramarine },
				{ shape: "Circle", colour: this.foregrounds.turf },
				{ shape: "Diamond", colour: this.foregrounds.caterpillar }
			];
		}
	},
	methods: {
		fillRandomIndexes() {

			let arr = [];

			for(let i = 0; i < 5; i++) {
				arr.push(this.getRandomInt(0, this.shapes.length - 1));
			}

			this.randomIndexes = arr;
		},
		getRandomInt(min, max) {
			return Math.floor(Math.random() * (max - min + 1) + min);
		}
	}
};
</script>

<style lang="scss">
@import "~@/assets/scss/variables";

.hero {
	min-height: auto;
}

.row {
	display: grid;
	align-items: center;
	grid-template-columns: repeat(7, 1fr);
}

.logo-container {
	width: 100%;
	grid-column: 1 / 4;
	padding-left: 65px;
	@media screen and (max-width: 910px) {
		padding-left: 30px;
	}
	@media screen and (max-width: $sm) {
		grid-column: 1/6;
	}

	img {
		width: 50%;
		max-width: 200px;
	}
}

.top-svg-shapes {
	grid-column: 4 / 8;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	@media screen and (max-width: $sm) {
		grid-column: 6/8;
		grid-template-columns: repeat(2, 1fr);
	}
}

.hero-message {
	background-color: $cobalt;
	color: $library;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding: 40px 65px;
	box-sizing: border-box;
	grid-column: 1 / 5;
	@media screen and (max-width: 910px) {
		grid-column: 1 / 8;
		padding: 80px 30px;
	}

	h1 {
		font-size: 70px;
		font-weight: 600;
		margin-bottom: 0;
		line-height: 83px;
		color: $library;
		margin-top: 0;
		@media screen and (max-width: $lg) {
			font-size: 50px;
      line-height: 58px;
		}
	}

	p {
		font-size: 27px;
		font-weight: 400;
		line-height: 34px;
		color: $library;
		margin-bottom: 0;
		@media screen and (max-width: $lg) {
			font-size: 20px;
      line-height: 25px;
		}
	}
}

.svg-icons {
	width: 100%;
	grid-column: 5 / 8;
	display: grid;
	grid-auto-flow: row;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(4, 1fr);
	@media screen and (max-width: 910px) {
		display: none;
	}

	.animated-shape {
		&:first-child {
			grid-column: 1/3;
			grid-row: 1/3;
		}
	}
}
</style>
