<template>
	<div class="playground-frame frame-6">
		
		<AnimatedSVG :play-animation="true" active-shape="FourCircles" :active-colour="foregrounds.ultramarine" />
		
		<AnimatedSVG :play-animation="true" active-shape="Star" :active-colour="foregrounds.turf" />
		
		<AnimatedSVG :play-animation="true" active-shape="EightHorizontalArrows" :active-colour="foregrounds.persimmon" />
		
		<AnimatedSVG :play-animation="true" active-shape="TwoVerticalArrows" :active-colour="foregrounds.turf" :active-background-colour="foregrounds.caterpillar" />
		
		<AnimatedSVG :play-animation="true" active-shape="ThreeHorizontalLines" :active-colour="foregrounds.sky" />
		
		<AnimatedSVG :play-animation="true" active-shape="Decagon" :active-colour="foregrounds.persimmon" />
		
		<div class="middle-section">
			
			<AnimatedSVG :play-animation="true" active-shape="Diamond" :active-colour="foregrounds.persimmon" />
			
			<div class="final-message"><p>You have everything you need for lifelong learning.</p></div>
			
			<AnimatedSVG :play-animation="true" active-shape="TwoMountains" :active-colour="foregrounds.caterpillar" />
		
		</div>
		
		<AnimatedSVG :play-animation="true" active-shape="TwoSmiles" :active-colour="foregrounds.sky" />
		
		<AnimatedSVG :play-animation="true" active-shape="Circle" :active-colour="foregrounds.turf" />
		
		<AnimatedSVG :play-animation="true" active-shape="Checkerboard" :active-colour="foregrounds.caterpillar" />
		
		<AnimatedSVG :play-animation="true" active-shape="Star" :active-colour="foregrounds.persimmon" />
		
		<AnimatedSVG :play-animation="true" active-shape="TwoHalfCircles" :active-colour="foregrounds.ultramarine" />
		
		<AnimatedSVG :play-animation="true" active-shape="CurvedDiamond" :active-colour="foregrounds.turf" />
	
	</div>
</template>

<script>
import AnimatedSVG from "@/components/AnimatedSVG";
import { branding } from "@/mixins/brand";

export default {
	name: "Frame6",
	components: { AnimatedSVG },
	props: {
		beenSeen: {
			type: Boolean,
			default: false
		}
	},
	mixins: [branding]
};
</script>

<style lang="scss">

@import "~@/assets/scss/_variables.scss";

.frame-6 {
	grid-template-columns: repeat(6, 1fr);
	background: $cobalt;
	
	.middle-section {
		grid-column: 1/7;
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		
		.final-message {
			grid-column: 2/6;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 10px;
			
			p {
				color: white;
				font-size: 27px;
				line-height: 34px;
				font-weight: 500;
				margin: 0;
				@media screen and (max-width: $sm) {
					font-size: 18px;
					line-height: 23px;
				}
			}
		}
		
		@media screen and (max-width: $sm) {
			grid-template-columns: repeat(2, 1fr);
			
			.final-message {
				grid-column: 1/3;
				padding: 100px 20px;
			}
			
			> div:last-child {
				grid-column: 2/3;
			}
			
		}

	}
	
}
</style>